import logo from './logo.svg';
import './App.css';
import Navbar from './Components/Navbar';
import { Routes, Route } from 'react-router-dom';
import Home from './Components/Home/Home';
import Contact from './Components/Contact/Contact';
import Footer from './Components/Footer';
import ProductInfo from './Components/Home/ProductInfo';
import Search from './Components/Home/Search';

function App() {
  return (
    <div class="bg-zinc-100 relative">
      {/* <BackgroundParticles/> */}
      <Navbar/>
      <Routes>
        <Route exact path="/" element={<Home/>}/>
        <Route exact path="/contacto" element={<Contact/>}/>
        <Route path="/product/:productId" element={<ProductInfo/>}/>
        <Route path="/search" element={<Search/>}/>
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
