import React from 'react'
import SearchResults from './SearchResults'
import ProductGrid from './ProductGrid'
import { useSearchParams } from 'react-router-dom';

function Search() {

  const [searchParams, setSearchParams] = useSearchParams();
  let sParams = searchParams.get("q")

  return (
    <div>
      <SearchResults title={`Resultados para "${sParams}"`} />
    </div>
  )
}

export default Search