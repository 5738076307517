import React, { useRef } from 'react'
import Card from './Card'
import { useState } from 'react'
import { useEffect } from 'react'

function ProductGrid(props) {

    const cardsUrl = props.url
    const [response, setResponse] = useState([])
    const [errorMsg, setErrorMsg] = useState(false)

    let getData = () => {

        return fetch(cardsUrl)

            .then(response => response.json())
            .then(data => { (setResponse(data))})
            
            .catch(error => { console.log(error); setErrorMsg(true)} )

    }

    useEffect(() => {
        getData();
        // if (response.ok) { errorMsgRef.current("true")}
    }, [])

    return (
        <div>
            <div class="container mx-auto shadow-md p-3 rounded-md mt-8 bg-white">
                <p class="text-2xl lg:ml-7 ml-4 font-bold">{props.title}</p>
                <div class="grid lg:grid-cols-5 grid-cols-2 mx-auto">

                    <p className={errorMsg == false ? "hidden" : "text-md p-2"}>Debido a un error de hosting no se pueden mostrar los productos en este momento.</p>
                    {response.map(product =>
                        <div className="col-lg-2 my-3">
                            <Card
                                prodId={product.id}
                                name={product.name}
                                price={product.price}
                                img={product.imageUrl}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ProductGrid