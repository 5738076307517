import React, { useContext } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useParams } from 'react-router-dom'

function ProductInfo(props) {

  const [searchParams, setSearchParams] = useSearchParams()

  let id = window.location.pathname
  // const id = useParams()
  const [response, setResponse] = useState([])

  let getProduct = () => {
    
    return fetch(`http://192.168.1.151:8080/api/v1${id}`)
      .then(response => response.json())
      .then(data => (setResponse(data)))
  }

  useEffect(() => {
    getProduct()
  }, [])

  let sellers = [{name:'Carlos Pereyra', contact:'+54947290976'},{name:'Juana Montebello', contact:'+54958470591'},{name:'Rodrigo Cielos', contact:'+54957380512'},{name:'Esteban Montenegro', contact:'+54957840367'}]
  let sellerData = sellers[Math.floor(Math.random() * sellers.length)];

  return (
    <div>
      <div className="container w-full m-auto">

        <div class="grid lg:grid-cols-2 md:grid-cols-1 m-auto mt-16">
          <div class="col-2">
            <img className="rounded-lg size-full" src={response.imageUrl} />
          </div>
          <div class="w-auto rounded-md col-2">
            <div className="bg-gray-50 h-auto shadow-md border p-12 lg:rounded-r-lg">
              <p class="text-xl md:text-2xl lg:text-2xl text-center font-bold uppercase">{response.name}.</p>
              <p class="border-b-4 border-zinc-200 pb-2 text-center text-lg">{response.description}</p>
              <p class="text-xl text-center text-emerald-700 font-bold pt-2">${response.price}</p>
              <p class="text-lg font-bold"><i class="fa-solid fa-circle-info pb-2 text-indigo-500"></i> Más información del producto:</p>
              <div className="divide-y-2 divide-indigo-300">
                <div className="py-2"><p className="text-md font-bold">Descripción:</p><span> {response.description}</span></div>
                <div className="py-2"><p className="text-md font-bold ">Envíos:</p><span>Acordar con el vendedor.</span></div>
                <div className="py-2"><p className="text-md font-bold ">Vendedor:</p><span>{sellerData.name}</span></div>
                <div className="py-2"><p className="text-md font-bold ">Contacto del vendedor:</p><span>{sellerData.contact}</span></div>
              </div>
              <br />
              </div>
            </div>
          </div>
        </div>

      </div>
      )
}

      export default ProductInfo