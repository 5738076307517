import React from 'react'
import Navbar from '../Navbar'
import Carousel from './Carousel'
import Card from './Card'
import ProductGrid from './ProductGrid'
import Footer from '../Footer'
import SearchResults from './SearchResults'

function Home() {

  return (
    <div>
      <Carousel class="flex" />
    
      <ProductGrid url="http://192.168.1.151:8080/api/v1/product/card" title="Productos" />

      <div class="container mx-auto shadow-md p-3 rounded-md mt-8 bg-white h-auto">
        <p class="text-xl lg:ml-7 ml-4 pb-7 font-bold">Ellos nos eligieron</p>
        <div class="grid lg:grid-cols-5 grid-cols-2 mx-auto *:rounded-lg *:px-2">
          <img src="./specificimg/drdog.jpg" alt="Dr.Dog" />
          <img src="./specificimg/petcare.jpg" alt="Pet Care" />
          <img src="./specificimg/royalcanin.jpg" alt="Royal Canin" />
          <img src="./specificimg/veterinaire.jpg" alt="Veterinaire" />
          <img src="./specificimg/carevet.jpg" alt="Carevet" />
        </div>
      </div>

    </div>
  )
}

export default Home