import React from 'react'
import ProductInfo from './ProductInfo'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'


function Card(props) {

    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()

    // let redirectFunction = () => {
    //     navigate(`/product/${props.prodId}`)
    // }

    return (

        <div class="w-full max-w-sm dark:bg-gray-800 dark:border-gray-700 col block shadow-md hover:shadow-xl hover:bg-gray-100 transition">
            {/* <a
                // href={`/product/40`}>
                 onClick={() => redirectFunction()}> 
                <img class="p-4 pt-0 rounded-xl" src={props.img} alt={props.name} />
            </a> */}

            <Link to={`/product/${props.prodId}`}><img class="p-4 pt-0 rounded-xl" src={props.img} alt={props.name} /></Link>

            <div class="px-5 pb-5">
                <a href="#">
                    <h5 class="text-lg line-clamp-2 tracking-tight text-gray-900 dark:text-white leading-6 break-words text-ellipsis lg:h-6 h-12 align-middle">{props.name}</h5>
                </a>
                <div class="flex items-center justify-between">
                    <span class="text-xl font-bold text-gray-900 dark:text-white lg:mt-4">${props.price}</span>
                </div>
            </div>
        </div>
    )
}

export default Card