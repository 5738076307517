import React, { useContext, useEffect, useState, useRef } from 'react'
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
// import { ContactContext, ContactContextProvider } from './Contact/ContactContext';

function Navbar() {

    // let { contactDark, setContactDark } = useContext(ContactContext)

    let ToggleFunction = () => {
        let Navlinks = document.getElementById("Navlinks");

        Navlinks.classList.toggle('hidden');
    }

    const [searchParams, setSearchParams] = useSearchParams()
    const [searchInput, setSearchInput] = useState("")
    const navigate = useNavigate()
    const [message, setMessage] = useState('')
    const inputRef = useRef(null)
    const mobileInputRef = useRef(null)

    let searchBarSubmit = (e) => {

        e.preventDefault();
        setSearchInput(inputRef.current.value.toLowerCase())
        setSearchParams({ q: searchInput })
        navigate(`/search?q=${searchInput}`)
    }

    let mobileSearchBarSubmit = (e) => {

        e.preventDefault();
        setSearchInput(mobileInputRef.current.value.toLowerCase())
        setSearchParams({ q: searchInput })
        navigate(`/search?q=${searchInput}`)
    }

    return (
        <div>
            <nav class="flex items-center justify-between flex-wrap p-6 lg:flow-root py-2 lg:pt-0 lg:py-4 relative bg-white align-center">
                {/* LOGO */}
                <div class="flex items-center flex-shrink-0 relative h-10 lg:h-14 w-full">

                    <img class="fill-current size-12 md:size-16 lg:size-16" src="../specificimg/navbarlight.png" />

                    <Link to="/"><span class="font-semibold text-xl block dark:text-slate-200 ml-3">Perretes</span></Link>

                    <div class="container">
                        {/* SEARCHBAR PC */}
                        <form onSubmit={searchBarSubmit}>
                            <input type="text" ref={inputRef}
                                class="hidden lg:block lg:relative mx-auto w-7/12 h-10 ps-4 bg-slate-100 text-black text-base border border-slate-500 focus:outline focus:outline-offset-0 outline-gray-950 rounded-sm dark:text-slate-100 dark:bg-darkBg dark:border-1"
                                placeholder="Busca algo...">
                            </input>
                        </form>
                    </div>
                    {/* <button class="mr-0 text-2xl text-sky-700 hidden lg:flex" onClick={() => ToggleTheme()}><i class="fa-solid fa-moon"></i></button> */}

                    {/* LOGO */}

                    {/* TOGGLER */}
                    <div class="flex lg:hidden">
                        <button id='TogglerButton' onClick={() => ToggleFunction()} class="flex">
                            <i class="fa-solid fa-bars text-lg mt-2"></i>
                        </button>
                    </div>
                </div>
                {/* TOGGLER */}

                {/* NAVLINKS */}
                <div class="w-full flex justify-center lg:flex lg:items-center lg:h-6 pt-1 dark:text-slate-300 text-gray-600">
                    <div class="hidden lg:block md:block md:w-auto lg:pl-2" id='Navlinks'>
                        <div class="lg:text-lg lg:flex-grow md:text-2xl text-xl">
                            <Link to="/" class="hover:text-blue-600 dark:hover:text-blue-200 block pt-4 pb-0 lg:inline-block lg:mt-0 mr-4 lg:hover:border-b-2 hover:border-indigo-300">
                                Inicio
                            </Link>
                            <Link to="/contacto" class=" hover:text-blue-600 dark:hover:text-blue-200 block pt-4 pb-3 lg:pb-0 lg:inline-block lg:mt-0 mr-4 lg:hover:border-b-2 hover:border-indigo-300">
                                Contacto
                            </Link>

                        </div>
                    </div>
                </div>
                {/* NAVLINKS */}

                {/* RESPONSIVE MENU */}
                <div class="container flex justify-evenly text-gray-500 dark:text-slate-200 items-center">
                    {/* SEARCHBAR MOBILE */}
                    <form onSubmit={mobileSearchBarSubmit}>
                        <input type="text"
                            ref={mobileInputRef}
                            class="mt-1 mx-auto w-12/12 lg:hidden h-10 flex ps-4 text-black text-base border border-slate-500 focus:outline focus:outline-offset-0 outline-gray-950 rounded-md dark:bg-darkBg dark:text-slate-100 bg-slate-100"
                            placeholder="Busca algo...">
                        </input>
                    </form>
                </div>
                {/* RESPONSIVE MENU */}
            </nav>
        </div>
    )
}

export default Navbar