import React from 'react'

function Form() {
  return (
    <div>
      <form class="w-auto my-4 mx-3 *:text-lg font-medium">
        <div class="mb-5">
          <input type="email" class="bg-transparent border-b border-neutral-500 dark:border-slate-200  text-gray-900 block w-full p-2.5 dark:placeholder-gray-300 placeholder-gray-500 dark:text-white dark:-light focus:outline-none  focus:border-indigo-400" placeholder="Tu email..." required />
        </div>
        <div class="mb-5">
          <input type="text" class="lg:mt-4 bg-transparent border-b border-neutral-500 dark:border-slate-200 text-gray-900 dark:text-white rounded-lgblock w-full p-2.5 dark:placeholder-gray-300 placeholder-gray-500  dark:-light focus:outline-none focus:border-indigo-400" placeholder="Tu nombre..." required />
        </div>
        <textarea id="message" rows="4" class="border-neutral-500 dark:border-slate-200 bg-transparent resize-none block p-2.5 lg:mt-10 w-full text-gray-900 dark:text-white placeholder-gray-500 rounded-lg border  focus:ring-blue-500  dark:placeholder-gray-300 dark:text-whitefocus:outline-none focus:outline-none focus:border-indigo-400" placeholder="Tu nombre..."></textarea>
        <button type="submit" class="border-none w-full text-medium transition duration-200 ease-in-out mt-3 text-white bg-indigo-500 hover:bg-indigo-800 focus:outline-none font-medium rounded-md px-5 py-2.5 text-center">Enviar correo</button>
      </form>
    </div>
  )
}

export default Form