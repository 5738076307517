import React from 'react'

function Footer() {
    return (
        <div>
            <footer class="bg-white dark:bg-gray-900 w-full mt-96">
                <div class="w-full max-w-screen-xl mx-auto p-4 md:py-8">
                    <div class="sm:flex sm:items-center sm:justify-between">
                        <a href="/" class="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
                            <img src="../specificimg/navbarlight.png" class="h-8" alt="´Logo" />
                            <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Perretes</span>
                        </a>
                        <ul class="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
                            <li>
                                <p class="me-4 md:me-6">Matías Diaconchuk</p>
                            </li>
                            <li>
                                <a href="/contacto" class="hover:underline">Contacto</a>
                            </li>
                        </ul>
                    </div>
                    <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
                    <span class="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2024 Perretes™. Todos los derechos reservados</span>
                </div>
            </footer>
        </div>
    )
}

export default Footer