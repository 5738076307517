import React from 'react'
import { useState } from 'react'

function Carousel() {

    let slides = [
        "./specificimg/carousel1.jpg",
        "./specificimg/carousel2.jpg",
        "./specificimg/carousel3.jpg"
    ]

    let [current, setCurrent] = useState(0);

    let previousSlide = () => {
        if (current === 0) setCurrent(slides.length - 1);
        else setCurrent(current - 1);
    };

    let nextSlide = () => {
        if (current === slides.length - 1) setCurrent(0);
        else setCurrent(current + 1);
    };


    return (
        <div className="lg:w-10/12 w-full h-40 lg:h-auto mx-auto overflow-hidden relative">
            <div
                className={`flex transition ease-out duration-700 *:rounded-md *:w-full h-full`}
                style={{
                    transform: `translateX(-${current * 100}%)`,
                }}>

                {slides.map((s) => {
                    return <img src={s} class="lg:size-full"/>;
                })}
            </div>

            <div className="absolute top-0 h-full justify-between w-full items-center flex text-white px-10 lg:text-2xl">
                <button onClick={previousSlide}><i class="fa-solid fa-chevron-left p-3 py-2 hover:bg-neutral-900 bg-neutral-600 rounded-full ml-0"></i></button>
                <button onClick={nextSlide}><i class="fa-solid fa-chevron-right p-3 py-2 hover:bg-neutral-900 bg-neutral-600 rounded-full"></i></button>
            </div>

            <div className="absolute bottom-0 py-4 justify-center gap-3 w-full *:transition *:ease-in-out *:duration-150 hidden md:flex lg:flex">
                {slides.map((s, i) => {
                    return (
                        <div
                            onClick={() => {
                                setCurrent(i);
                            }}
                            key={"circle" + i}
                            className={`rounded-full size-5 cursor-pointer  ${i == current ? "bg-white" : "bg-gray-500"
                                }`}
                        ></div>
                    );
                })}
            </div>
        </div>
    )
}

export default Carousel